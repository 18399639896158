import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { Editor } from "@tiptap/core";
import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import type {
  ActionItem,
  CheckInMode,
  Client,
  Message,
  MessageWorkoutsMissed,
  Reaction,
  Trainer,
} from "@trainwell/types";
import { format, isPast } from "date-fns";
import type { ChatSort } from "src/features/chat/ChatSortButton";
import { magicKeySuggestionOptions } from "src/features/chat/magicKeySuggestionOptions";
import { socket } from "src/hooks/useSocket";
import { trainerHasAccess } from "src/lib/accessRoles";
import {
  getChatFromTicket,
  getChatsMatchingFilter,
  getSerializedMessage,
  sortChatsByNewestActionItem,
  sortChatsByOldestActionItem,
} from "src/lib/chat";
import {
  getClientDisplayName,
  sortClientsByLastMessageDate,
  sortClientsByLastWorkoutDate,
  sortClientsByMissedDays,
  sortClientsByName,
  sortClientsByNextWorkout,
  sortClientsByTenureCoach,
  sortClientsByTenureTrainwell,
  sortClientsByWeeks,
  sortClientsByWorkoutCompletion,
} from "src/lib/client";
import { getTrainerName } from "src/lib/coachUtility";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/slices/store";
import type { ThemeMode } from "src/theme/themeModes";
import {
  clearActionItemWithType,
  selectAllActionItems,
} from "./actionItemSlice";
import { selectClientById, type ClientInfo } from "./clientsSlice";
import { selectTicketById, upsertTickets } from "./ticketsSlice";
import { selectIsGhosting, selectPrimaryTrainer } from "./trainerSlice";
import { updateTrainer } from "./trainersSlice";

export interface Chat {
  /** userId for client chats, chatId for group chats */
  id: string;

  forceTrainerId?: string;
  /** Used for interim clients */
  oldTrainerId?: string;

  pinned: boolean;
  clientName: string;
  messages: Message[];
  firstMessageFetchState: "idle" | "fetching" | "done";
  dateCreated: string;

  clientTimezoneOffset?: number;
  clientHeadshotURL: string;
  oldestMessageNeedingResponse?: string;
  oldestUnreadMessageFromClient?: string;
  oldestUnreadMessageIdByTrainer?: string;
  oldestQuestionFromClient?: string;

  isGroupChat: boolean;
  isTrainwell?: boolean;
  ticketId?: string;
  memberIds?: string[];

  /** Used by Virtuoso for virtual scrolling */
  firstChatIndex: number;

  loadingState: "idle" | "loading" | "succeeded" | "failed" | "endReached";

  forceDisabled?: boolean;

  banner?: string;
}

export const fetchChats = createAsyncThunk(
  "chat/fetchChats",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const actionItems = selectAllActionItems(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const newClients = JSON.parse(
      JSON.stringify(state.clients.clients),
    ) as Client[];

    const newChats: Chat[] = [];

    newClients.forEach((client) => {
      let unreadDate: string | undefined = undefined;

      const questionAi = actionItems.find(
        (ai) =>
          ai.user_id === client.user_id &&
          ai.type === "respond" &&
          ai.respond_type === "question",
      );
      const respondAi = actionItems.find(
        (ai) =>
          ai.user_id === client.user_id &&
          ai.type === "respond" &&
          (ai.respond_type === "message" || ai.respond_type === "habit"),
      );
      const unreadAi = actionItems.find(
        (ai) => ai.user_id === client.user_id && ai.type === "read_chat",
      );

      if (unreadAi) {
        unreadDate =
          (unreadAi.date_to_send as string | null) ??
          (unreadAi.date_created as string);
      }

      if (trainer.force_unread_chat_ids.includes(client.user_id)) {
        unreadDate = new Date().toISOString();
      }

      const isClientBannerActive = Boolean(
        client.banner_coach?.active && client.banner_coach.text,
      );
      const isCoachBannerActive = Boolean(
        trainer.banner?.active && trainer.banner.text,
      );

      newChats.push({
        isGroupChat: false,
        id: client.user_id,
        clientTimezoneOffset: client.default_timezone_offset,
        messages: client.latest_message?.send_date
          ? [client.latest_message]
          : [],
        firstMessageFetchState: "idle",
        clientName: getClientDisplayName(client),
        clientHeadshotURL: client.headshot_url,
        loadingState: "idle",
        oldestUnreadMessageFromClient: unreadDate,
        oldestMessageNeedingResponse: !respondAi
          ? undefined
          : ((respondAi.date_to_send as string | null) ??
            (respondAi.date_created as string)),
        oldestQuestionFromClient: !questionAi
          ? undefined
          : ((questionAi.date_to_send as string | null) ??
            (questionAi.date_created as string)),
        dateCreated: client.account.membership
          .date_membership_started as string,
        pinned: trainer.pinned_chat_ids.includes(client.user_id),
        firstChatIndex: 1000000,
        forceDisabled: Boolean(
          client.trainer_id_interim && client.trainer_id === trainer.trainer_id,
        ),
        banner: isClientBannerActive
          ? client.banner_coach?.text
          : isCoachBannerActive
            ? trainer.banner?.text
            : undefined,
        oldTrainerId: client.trainer_id_interim ? client.trainer_id : undefined,
      });
    });

    return newChats;
  },
);

export const fetchTicketChats = createAsyncThunk(
  "chat/fetchTicketChats",
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const newChats: Chat[] = [];

    const ticketChats = await api.trainers.getTicketChats(trainer!.trainer_id);

    ticketChats.forEach((ticketChat) => {
      const chat = getChatFromTicket(
        ticketChat,
        getTrainerName(
          ticketChat.support_ticket.trainer_id,
          state.trainers.trainerNames,
        ),
        trainer!.trainer_id,
      );

      newChats.push(chat);
    });

    const tickets = ticketChats.map((chat) => chat.support_ticket);

    dispatch(upsertTickets({ tickets: tickets }));

    return newChats;
  },
);

export const fetchFirstMessages = createAsyncThunk(
  "chat/fetchFirstMessages",
  async (chatId: string, { getState }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const chat = state.chat.chats[chatId];

    if (chat && trainer) {
      console.log(
        "fetch first messages for",
        chatId,
        chat.oldTrainerId ?? chat.forceTrainerId ?? trainer.trainer_id,
      );

      const messageRes = await api.messages.findMany({
        fromId: chat.isGroupChat
          ? undefined
          : (chat.oldTrainerId ?? chat.forceTrainerId ?? trainer.trainer_id),
        toId: state.chat.chats[chatId].id,
        beforeDate: new Date().toISOString(),
      });

      const messages = messageRes.messages;

      messages.sort((a, b) =>
        (a.send_date as string).localeCompare(b.send_date as string),
      );

      if (state.app.isAuditMode && messages.length > 0) {
        const earliestMessageDate = messages[0].send_date as string;
        const latestMessageDate = messages[messages.length - 1]
          .send_date as string;

        const vacationsRes = await api.vacations.getMany({
          trainerId: trainer.trainer_id,
          startingAfter: earliestMessageDate,
          startingbefore: latestMessageDate,
        });

        for (const vacation of vacationsRes.vacations) {
          messages.push({
            message_id: vacation.id,
            from_id: chatId,
            to_id: trainer.trainer_id,
            coach_only: true,
            type: "notification",
            notification_title: "Vacation",
            text: `${vacation.type} from ${format(vacation.date_start, "MMM d h:mmaaa")} to ${format(vacation.date_end, "MMM d h:mmaaa")}`,
            send_date: vacation.date_start,
            is_fake: true,
          });
        }

        messages.sort((a, b) =>
          (a.send_date as string).localeCompare(b.send_date as string),
        );
      }

      return { messages: messages };
    }
  },
);

export const fetchOfficialChats = createAsyncThunk(
  "chat/fetchOfficialChats",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    const newChats: Chat[] = [];

    if (
      trainerHasAccess(trainer?.access_roles, "official_trainwell_group_chats")
    ) {
      const officialTrainwellChats =
        await api.messages.getOfficialTrainwellChats();

      officialTrainwellChats.forEach((chat) => {
        newChats.push({
          isGroupChat: true,
          id: chat.chat_id,
          messages: chat.latest_message ? [chat.latest_message as any] : [],
          firstMessageFetchState: "idle",
          clientName: chat.user_full_name ?? chat.name,
          clientHeadshotURL: chat.user_headshot_url ?? "",
          loadingState: "idle",
          oldestUnreadMessageFromClient:
            chat.latest_message &&
            (chat.latest_message as any).trainer_id !== trainer?.trainer_id &&
            !(chat.latest_message as any).read_date
              ? new Date().toISOString()
              : undefined,
          dateCreated: chat.date_created as string,
          isTrainwell: true,
          pinned: false,
          firstChatIndex: 1000000,
        });
      });
    }

    return newChats;
  },
);

export const openChat = createAsyncThunk(
  "chat/openChat",
  async (data: { chatId: string; forceTrainerId?: string }, { getState }) => {
    const { chatId, forceTrainerId } = data;

    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    if (chatId === state.chat.selectedChatId && !forceTrainerId) {
      return;
    }

    if (state.chat.view === "threads") {
      return;
    }

    if (state.chat.mediaUploadUi === "uploading") {
      return;
    }

    if (state.chat.chats[chatId] && !forceTrainerId) {
      return { messages: [] };
    } else {
      console.log(`Chat: found a user_id but no chat, manually fetching info`);

      const client = await api.clients.getOne(chatId, true);

      console.log(
        `Chat: fetching chat between\ntrainer_id: ${
          forceTrainerId ?? client.trainer_id
        }\nuser_id: ${client.user_id}`,
      );

      const messagesRes = await api.messages.findMany({
        fromId: forceTrainerId ?? client.trainer_id,
        toId: client.user_id,
        beforeDate: new Date().toISOString(),
      });

      const newChat: Chat = {
        clientName: client.full_name,
        clientHeadshotURL: client.headshot_url,
        loadingState: "idle",
        messages: messagesRes.messages,
        firstMessageFetchState: "done",
        id: chatId,
        isGroupChat: false,
        dateCreated: new Date().toISOString(),
        pinned: false,
        forceTrainerId: forceTrainerId,
        firstChatIndex: 1000000,
      };

      return {
        messages: messagesRes.messages,
        chat: newChat,
        forceOpenChat: state.app.dashMode !== "programming",
      };
    }
  },
);

export const fetchMoreMessages = createAsyncThunk(
  "chat/fetchMoreMessages",
  async (
    data: { chatId: string; all?: boolean },
    { getState, rejectWithValue },
  ) => {
    const { chatId, all } = data;

    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const chat = state.chat.chats[chatId];

    if (!chat) {
      throw new Error("No chat");
    }

    if (chat.loadingState === "endReached") {
      console.log("Chat: Skip fetching messages, end reached");

      return rejectWithValue("endReached");
    }

    // if (chat.loadingState === "loading") {
    //   console.log("Chat: Skip fetching messages, loading");

    //   return rejectWithValue(null);
    // }

    console.log("Chat: Fetch more messages");

    const earliestStartDate = chat.messages[0].send_date as string;

    const messageRes = await api.messages.findMany({
      fromId: chat.isGroupChat
        ? undefined
        : (chat.oldTrainerId ?? chat.forceTrainerId ?? trainer!.trainer_id),
      toId: chat.id,
      beforeDate: earliestStartDate,
      limit: all ? -1 : undefined,
    });

    const messages = messageRes.messages;

    messages.sort((a, b) =>
      (a.send_date as string).localeCompare(b.send_date as string),
    );

    if (state.app.isAuditMode && messages.length > 0) {
      const earliestNewMessageDate = messages[0].send_date as string;

      const vacationsRes = await api.vacations.getMany({
        trainerId: trainer.trainer_id,
        startingAfter: earliestNewMessageDate,
        startingbefore: earliestStartDate,
      });

      for (const vacation of vacationsRes.vacations) {
        messages.push({
          message_id: vacation.id,
          from_id: chatId,
          to_id: trainer.trainer_id,
          coach_only: true,
          type: "notification",
          notification_title: "Vacation",
          text: `${vacation.type} from ${format(vacation.date_start, "MMM d h:mmaaa")} to ${format(vacation.date_end, "MMM d h:mmaaa")}`,
          send_date: vacation.date_start,
          is_fake: true,
        });
      }

      messages.sort((a, b) =>
        (a.send_date as string).localeCompare(b.send_date as string),
      );
    }

    return { messages: messages };
  },
);

export const openTicketChat = createAsyncThunk(
  "chat/openTicketChat",
  async (ticketId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (state.chat.mediaUploadUi !== "uploading") {
      const trainer = selectPrimaryTrainer(state);

      const ticketChats: Chat[] = [];

      for (const chatId in state.chat.chats) {
        if (state.chat.chats[chatId].ticketId) {
          ticketChats.push(state.chat.chats[chatId]);
        }
      }

      const chatIndex = ticketChats.findIndex(
        (chat) => chat.ticketId === ticketId,
      );

      if (chatIndex !== -1) {
        const messageRes = await api.messages.findMany({
          toId: ticketChats[chatIndex].id,
          beforeDate: new Date().toISOString(),
        });

        return messageRes.messages;
      } else {
        const ticketChat = await api.tickets.getChat(ticketId);

        const chat = getChatFromTicket(
          ticketChat,
          getTrainerName(
            ticketChat.support_ticket.trainer_id,
            state.trainers.trainerNames,
          ),
          trainer!.trainer_id,
        );

        dispatch(addChat(chat));

        const messageRes = await api.messages.findMany({
          toId: chat.id,
          beforeDate: new Date().toISOString(),
        });

        return messageRes.messages;
      }
    }
  },
);

export const markChatAsUnread = createAsyncThunk(
  "chat/markChatAsUnread",
  async (chatId: string | undefined, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);
    if (!trainer) {
      throw new Error("No trainer");
    }

    const chatIds = chatId ? [chatId] : state.chat.selectedChatIds;

    const newUnreadChats = [
      ...new Set([...trainer.force_unread_chat_ids, ...chatIds]),
    ];

    dispatch(
      updateTrainer({
        trainer_id: trainer.trainer_id,
        force_unread_chat_ids: newUnreadChats,
      }),
    );

    return chatIds;
  },
);

export const toggleChatPinned = createAsyncThunk(
  "chat/toggleChatPinned",
  async (chatId: string | undefined, { getState, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);
    if (!trainer) {
      throw new Error("No trainer");
    }

    const chatIds = chatId ?? state.chat.selectedChatIds;

    let newPinnedChats = [...trainer.pinned_chat_ids];

    if (chatId) {
      if (newPinnedChats.includes(chatId)) {
        newPinnedChats = newPinnedChats.filter((id) => id !== chatId);
      } else {
        newPinnedChats.push(chatId);
      }
    } else {
      newPinnedChats = [...new Set([...newPinnedChats, ...chatIds])];
    }

    dispatch(
      updateTrainer({
        trainer_id: trainer.trainer_id,
        pinned_chat_ids: newPinnedChats,
      }),
    );

    return newPinnedChats;
  },
);

export const deleteMessage = createAsyncThunk(
  "chat/deleteMessage",
  async (messageId: string) => {
    const response = await api.messages.removeOne(messageId);

    return response;
  },
);

export const sendMessageAsSms = createAsyncThunk(
  "chat/sendMessageAsSms",
  async (data: { messageId: string; chatId: string }) => {
    const response = await api.messages.sendAsSms(data.messageId);

    return response;
  },
);

export const sendMessageAsEmail = createAsyncThunk(
  "chat/sendMessageAsEmail",
  async (data: {
    messageId: string;
    chatId: string;
    body: string;
    subject?: string;
  }) => {
    const response = await api.messages.sendAsEmail(
      data.messageId,
      data.body,
      data.subject,
    );

    return response;
  },
);

export const updateMessage = createAsyncThunk(
  "chat/updateMessage",
  async (data: {
    messageId: string;
    reactions?: Reaction[];
    callDetails?: MessageWorkoutsMissed["content"]["call_details"];
    nice?: boolean;
  }) => {
    const response = await api.messages.updateOne(data.messageId, {
      reactions: data.reactions,
      call_details: data.callDetails,
      nice: data.nice,
    });

    return response;
  },
);

export const sendTextMessage = createAsyncThunk(
  "chat/sendTextMessage",
  async (
    data: {
      text: string;
      userId: string;
      toGroup?: boolean;
      asTrainwell?: boolean;
      sourceDetailed?: Message["source_detailed"];
    },
    { getState, dispatch },
  ) => {
    const {
      text,
      userId,
      toGroup = false,
      asTrainwell = false,
      sourceDetailed,
    } = data;

    console.log("Chat: send text message to: " + userId);

    const state = getState() as RootState;
    const trainer = selectPrimaryTrainer(state);
    const isGhosting = selectIsGhosting(state);
    const client = selectClientById(state, userId);

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    dispatch(readChat(userId));

    const fromTrainerId = client?.trainer_id_interim
      ? client.trainer_id
      : trainer.trainer_id;

    const { message } = await api.messages.sendOne({
      to_id: userId,
      from_id: toGroup && asTrainwell ? "copilot" : fromTrainerId,
      type: "text",
      text: text,
      source: "dashboard",
      force_send: isGhosting,
      source_detailed: sourceDetailed,
    });

    dispatch(
      addMessage({
        clientID: userId,
        message: message,
        isFromCoach: true,
      }),
    );
  },
);

export const sendVideoMessage = createAsyncThunk(
  "chat/sendVideoMessage",
  async (
    data: {
      video_url: string;
      thumbnail_url: string;
      userId: string;
      toGroup?: boolean;
      asTrainwell?: boolean;
      width: number;
      height: number;
    },
    { getState, dispatch },
  ) => {
    const {
      video_url,
      thumbnail_url,
      userId,
      toGroup = false,
      asTrainwell = false,
      width,
      height,
    } = data;

    console.log("Chat: send video message to: " + userId);

    const state = getState() as RootState;
    const trainer = selectPrimaryTrainer(state);
    const isGhosting = selectIsGhosting(state);
    const client = selectClientById(state, userId);

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    const fromTrainerId = client?.trainer_id_interim
      ? client.trainer_id
      : trainer.trainer_id;

    const { message } = await api.messages.sendOne({
      to_id: userId,
      from_id: toGroup && asTrainwell ? "copilot" : fromTrainerId,
      type: "video",
      media_url: video_url,
      thumbnail_url: thumbnail_url,
      source: "dashboard",
      force_send: isGhosting,
      width: width,
      height: height,
    });

    if (message) {
      dispatch(
        addMessage({
          clientID: userId,
          message: message,
          isFromCoach: true,
        }),
      );
    } else {
      throw new Error("Video failed to send");
    }
  },
);

export const sendImageMessage = createAsyncThunk(
  "chat/sendImageMessage",
  async (
    data: {
      image_url: string;
      userId: string;
      toGroup?: boolean;
      asTrainwell?: boolean;
      width: number;
      height: number;
    },
    { getState, dispatch },
  ) => {
    const {
      image_url,
      userId,
      toGroup = false,
      asTrainwell = false,
      width,
      height,
    } = data;

    console.log("Chat: send image message to: " + userId);

    const state = getState() as RootState;
    const trainer = selectPrimaryTrainer(state);
    const isGhosting = selectIsGhosting(state);
    const client = selectClientById(state, userId);

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    const fromTrainerId = client?.trainer_id_interim
      ? client.trainer_id
      : trainer.trainer_id;

    const { message } = await api.messages.sendOne({
      to_id: userId,
      from_id: toGroup && asTrainwell ? "copilot" : fromTrainerId,
      type: "image",
      media_url: image_url,
      source: "dashboard",
      force_send: isGhosting,
      width: width,
      height: height,
    });

    if (message) {
      dispatch(
        addMessage({
          clientID: userId,
          message: message,
          isFromCoach: true,
        }),
      );
    } else {
      throw new Error("Image failed to send");
    }
  },
);

export const readChat = createAsyncThunk(
  "chat/readChat",
  async (chatId: string, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);
    const isGhosting = selectIsGhosting(state);
    const client = selectClientById(state, chatId);

    const disableGhostingProtections = state.trainer.disableGhostingProtections;

    if (!trainer) {
      throw new Error("Trainer not found");
    }

    const fromTrainerId = client?.trainer_id_interim
      ? client.trainer_id
      : trainer.trainer_id;

    const chat = state.chat.chats[chatId];

    if (chat.forceTrainerId) {
      return rejectWithValue(null);
    }

    if (
      (!isGhosting || state.trainer.disableGhostingProtections) &&
      trainer.force_unread_chat_ids.includes(chatId)
    ) {
      const newChatIds = [...trainer.force_unread_chat_ids].filter(
        (id) => id !== chatId,
      );

      dispatch(
        updateTrainer({
          trainer_id: trainer.trainer_id,
          force_unread_chat_ids: newChatIds,
        }),
      );
    }

    let latestMessageToRead: Message | undefined;

    for (
      let messageIndex = chat.messages.length - 1;
      messageIndex >= 0;
      messageIndex--
    ) {
      const message = chat.messages[messageIndex];

      if (chat.isGroupChat) {
        if (
          message.from_id !== trainer.trainer_id &&
          (!message.read_statuses ||
            !message.read_statuses.hasOwnProperty(trainer.trainer_id) ||
            !message.read_statuses[trainer.trainer_id])
        ) {
          latestMessageToRead = message;

          break;
        }
      } else {
        if (message.from_id !== trainer.trainer_id && !message.read_date) {
          latestMessageToRead = message;

          break;
        }
      }
    }

    const actionItems = selectAllActionItems(state);

    const hasUnreadActionItems = actionItems.some(
      (ai) => ai.user_id === chat.id && ai.type === "read_chat",
    );

    if (!latestMessageToRead && !hasUnreadActionItems) {
      console.log(
        `Chat: Skip reading chat due to nothing to read (1): '${chat.id}'`,
      );

      return { isGhosting: isGhosting, disableGhostingProtections };
    }

    if (!isGhosting || state.trainer.disableGhostingProtections) {
      console.log(`Chat: Reading chat: '${chat.id}'`);

      if (chat.isGroupChat) {
        if (!latestMessageToRead) {
          console.log(
            `Chat: Skip reading chat due to nothing to read (2): '${chat.id}'`,
          );

          return { isGhosting: isGhosting, disableGhostingProtections };
        }

        socket.emit("readMessage", {
          from_id: latestMessageToRead.from_id,
          to_id: latestMessageToRead.to_id,
          message_id: latestMessageToRead.message_id,
          group_message: chat.isGroupChat,
          read_by: fromTrainerId,
        });
      } else {
        await api.messages
          .readClientMessages(fromTrainerId, chat.id)
          .catch((e) => {
            Sentry.captureException(e);
          });
      }
    } else {
      console.log(`Chat: Skip reading chat due to ghost mode: '${chat.id}'`);
    }

    return {
      isGhosting: isGhosting,
      trainerId: trainer.trainer_id,
      disableGhostingProtections,
    };
  },
);

export const fetchAllMessages = createAsyncThunk(
  "chat/fetchAllMessages",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const res = await api.trainers.getAllMessages(trainer.trainer_id);

    return res;
  },
);

export const toggleChatFilter = createAsyncThunk(
  "chat/toggleChatFilter",
  async (newFilter: string, { getState }) => {
    const state = getState() as RootState;

    let newFilteredChatIds: string[] = [];

    const currentIndex = state.chat.selectedFilters.indexOf(newFilter);

    const clientChats: Chat[] = [];

    for (const chatId in state.chat.chats) {
      if (!state.chat.chats[chatId].isGroupChat) {
        clientChats.push(state.chat.chats[chatId]);
      }
    }

    if (currentIndex === -1) {
      const filteredChatIdsToAdd = getChatsMatchingFilter(
        newFilter,
        clientChats,
        state.clients.clients,
        state.clients.clientInfo,
        state.actionItems.actionItems,
      ).map((chat) => chat.id);

      newFilteredChatIds = [
        ...new Set([...state.chat.selectedChatIds, ...filteredChatIdsToAdd]),
      ];
    } else {
      for (const filter of state.chat.selectedFilters) {
        if (filter === newFilter) {
          continue;
        }

        const filteredChatIdsToAdd = getChatsMatchingFilter(
          filter,
          clientChats,
          state.clients.clients,
          state.clients.clientInfo,
          state.actionItems.actionItems,
        ).map((chat) => chat.id);

        newFilteredChatIds = [...newFilteredChatIds, ...filteredChatIdsToAdd];
      }

      newFilteredChatIds = [...new Set(newFilteredChatIds)];
    }

    return newFilteredChatIds;
  },
);

export const addMessage = createAsyncThunk(
  "chat/addMessage",
  async (
    data: {
      clientID: string;
      message: Message;
      isFromCoach: boolean;
    },
    { dispatch },
  ) => {
    console.log("Redux: add message");

    if (data.message.to_id === data.clientID) {
      //Trainer sent a message

      dispatch(
        clearActionItemWithType({ userId: data.clientID, type: "respond" }),
      );
    }

    return;
  },
);

export const stageMassMessages = createAsyncThunk(
  "chat/stageMassMessages",
  async (data: { messageHtml: string; userIds?: string[] }, { getState }) => {
    const { messageHtml, userIds } = data;

    console.log("Redux: stage mass messages");

    const state = getState() as RootState;

    const massMessages = selectMassMessages(state, messageHtml as any, userIds);

    return massMessages;
  },
);

// Define a type for the slice state
interface ChatState {
  chats: Record<string, Chat>;
  pendingMessages: Record<string, string>;
  selectedChatId: string | undefined;
  mediaUploadUi: "hide" | "show" | "uploading";
  isChatFullscreen: boolean;
  isSelectingChats: boolean;
  selectedChatIds: string[];
  selectedFilters: string[];
  currentTab: "clients" | "tickets" | "official_trainwell";
  ticketChatsStatus: "idle" | "loading" | "succeeded" | "failed";
  officialChatsStatus: "idle" | "loading" | "succeeded" | "failed";
  allMessagesStatus: "idle" | "loading" | "succeeded" | "failed";
  focusedUserId: string | undefined;
  view: "default" | "threads" | "check_in";
  checkInMode: {
    quickMessageEnabled?: boolean;
    type?: CheckInMode;
    customUserIds?: null | string[];
    cachedType?: CheckInMode;
  };
  hideClientChat: boolean;
}

// Define the initial state using that type
const initialState: ChatState = {
  chats: {},
  pendingMessages: {},
  selectedChatId: undefined,
  mediaUploadUi: "hide",
  isChatFullscreen: false,
  isSelectingChats: false,
  selectedChatIds: [],
  selectedFilters: [],
  currentTab: "clients",
  ticketChatsStatus: "idle",
  officialChatsStatus: "idle",
  allMessagesStatus: "idle",
  focusedUserId: undefined,
  view: "default",
  checkInMode: {},
  hideClientChat: false,
};

export const chatSclice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetChat: () => initialState,
    addChat: (state, action: PayloadAction<Chat>) => {
      const newChat = action.payload;

      state.chats[newChat.id] = newChat;
    },
    setCheckInMode: (
      state,
      action: PayloadAction<ChatState["checkInMode"]>,
    ) => {
      state.checkInMode = action.payload;
    },
    setCurrentTab: (state, action: PayloadAction<ChatState["currentTab"]>) => {
      state.currentTab = action.payload;
    },
    toggleHideClientChat: (state) => {
      state.hideClientChat = !state.hideClientChat;
    },
    setHideClientChat: (state, action: PayloadAction<boolean>) => {
      state.hideClientChat = action.payload;
    },
    setFocusedUserId: (state, action: PayloadAction<string>) => {
      state.focusedUserId = action.payload;
    },
    setChatView: (state, action: PayloadAction<ChatState["view"]>) => {
      const view = action.payload;

      state.view = view;
      state.focusedUserId = undefined;
    },
    closeChat: (state) => {
      if (state.mediaUploadUi === "uploading") {
        return;
      }

      state.selectedChatId = undefined;
      state.mediaUploadUi = "hide";
    },
    toggleSelectingChats: (state) => {
      state.isSelectingChats = !state.isSelectingChats;
      state.selectedChatIds = [];
      state.selectedFilters = [];
    },
    toggleSelectedChat: (state, action: PayloadAction<string>) => {
      const chatId = action.payload;

      if (state.selectedChatIds.includes(chatId)) {
        state.selectedChatIds = state.selectedChatIds.filter(
          (id) => id !== chatId,
        );
      } else {
        state.selectedChatIds.push(chatId);
      }
    },
    toggleFullChatMode: (state) => {
      state.isChatFullscreen = !state.isChatFullscreen;
    },
    setMediaUploadUi: (
      state,
      action: PayloadAction<ChatState["mediaUploadUi"]>,
    ) => {
      const mediaUploadUi = action.payload;

      state.mediaUploadUi = mediaUploadUi;
    },
    setMessage: (
      state,
      action: PayloadAction<{ message: string; chatId: string }>,
    ) => {
      const { message, chatId } = action.payload;

      if (state.chats[chatId]) {
        state.pendingMessages[chatId] = message;
      }
    },
    updateMessageLocal: (state, action: PayloadAction<Message>) => {
      const newMessage = action.payload;

      let chatId = newMessage.from_id;

      if (!state.chats[chatId]) {
        chatId = newMessage.to_id;
      }

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === newMessage.message_id,
        );

        if (messageIndex !== -1) {
          state.chats[chatId].messages[messageIndex] = newMessage;
        }
      }
    },
    setSentMessage: (state, action: PayloadAction<string>) => {
      const userId = action.payload;

      if (state.chats[userId]) {
        state.chats[userId].oldestMessageNeedingResponse = undefined;
      }
    },
    markMessageAsRead: (
      state,
      action: PayloadAction<{
        userId: string;
        message: Message;
        didCoachRead: boolean;
        readBy?: string;
      }>,
    ) => {
      const { userId, message, didCoachRead, readBy } = action.payload;

      if (state.chats[userId]) {
        const messageIndex = state.chats[userId].messages.findIndex(
          (m) => m.message_id === message.message_id,
        );

        if (messageIndex !== -1) {
          state.chats[userId].messages[messageIndex].read_date =
            message.read_date;
          if (readBy) {
            state.chats[userId].messages[messageIndex].read_statuses = {
              ...state.chats[userId].messages[messageIndex].read_statuses,
              [readBy]: message.read_date ?? new Date().toISOString(),
            };
          }
        }

        if (didCoachRead) {
          console.log("Chat: Trainer read a message");

          state.chats[userId].oldestUnreadMessageFromClient = undefined;

          for (const message of state.chats[userId].messages) {
            state.chats[userId].messages.map((m) => {
              if (!m.read_date && m.to_id === message.to_id) {
                m.read_date = new Date().toISOString();
              }
            });
          }
        }
      }
    },
    markMessageAsTicketed: (
      state,
      action: PayloadAction<{
        messageId: string;
        userId: string;
        ticketId: string;
      }>,
    ) => {
      const { messageId, ticketId, userId } = action.payload;

      if (state.chats[userId]) {
        const messageIndex = state.chats[userId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          state.chats[userId].messages[messageIndex].issue_report_id = ticketId;
        }
      }
    },
    updateChat: (
      state,
      action: PayloadAction<Partial<Chat> & Pick<Chat, "id">>,
    ) => {
      const update = action.payload;

      if (state.chats[update.id]) {
        state.chats[update.id] = {
          ...state.chats[update.id],
          ...update,
        };
      }
    },
    resetPendingMessages: (state) => {
      state.pendingMessages = {};
    },
    syncActionItemsToChat: (state, action: PayloadAction<ActionItem[]>) => {
      const actionItems = action.payload;

      for (const chatId in state.chats) {
        const chat = state.chats[chatId];

        const relevantActionItems = actionItems.filter(
          (ai) => ai.user_id === chatId,
        );

        const questionAi = relevantActionItems.find(
          (ai) => ai.type === "respond" && ai.respond_type === "question",
        );
        const respondAi = relevantActionItems.find(
          (ai) =>
            ai.type === "respond" &&
            (ai.respond_type === "message" || ai.respond_type === "habit"),
        );
        const unreadAi = relevantActionItems.find(
          (ai) => ai.type === "read_chat",
        );

        if (questionAi) {
          chat.oldestQuestionFromClient =
            (questionAi.date_to_send as string | null) ??
            (questionAi.date_created as string);
        }

        if (respondAi) {
          chat.oldestMessageNeedingResponse =
            (respondAi.date_to_send as string | null) ??
            (respondAi.date_created as string);
        }

        if (unreadAi) {
          chat.oldestUnreadMessageFromClient =
            (unreadAi.date_to_send as string | null) ??
            (unreadAi.date_created as string);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteMessage.fulfilled, (state, action) => {
      const clientId = state.selectedChatId;
      const messageId = action.meta.arg;

      if (clientId && state.chats[clientId]) {
        const messageIndex = state.chats[clientId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        state.chats[clientId].messages.splice(messageIndex, 1);
      }
    });
    builder.addCase(openChat.pending, (state, action) => {
      const { chatId: userId } = action.meta.arg;

      if (state.selectedChatId === userId) {
        return;
      }

      if (state.chats[userId]) {
        state.chats[userId].firstMessageFetchState = "idle";
      }
    });
    builder.addCase(openChat.fulfilled, (state, action) => {
      const { chatId: userId, forceTrainerId } = action.meta.arg;

      if (!action.payload) {
        return;
      }

      const { chat, messages } = action.payload;

      if (forceTrainerId) {
        delete state.chats[userId];
      }

      if (state.chats[userId]) {
        state.chats[userId].firstChatIndex = 1000000;
      }

      if (messages) {
        console.log("Redux: open chat for client with user_id: " + userId);

        messages.sort((a, b) =>
          (a.send_date as string).localeCompare(b.send_date as string),
        );

        if (chat) {
          // A new chat was supplied, add it to state

          chat.messages = messages;
          state.chats[chat.id] = chat;
        } else {
          // Find the existing chat to add messages to
          if (state.chats[userId]) {
            state.chats[userId].messages = messages;
            state.chats[userId].loadingState = "succeeded";
          }
        }

        state.selectedChatId = userId;
      }

      state.mediaUploadUi = "hide";

      if (action.payload.forceOpenChat) {
        state.hideClientChat = false;
      }
    });
    builder.addCase(openTicketChat.fulfilled, (state, action) => {
      const ticketId = action.meta.arg;
      const messages = action.payload;

      if (messages) {
        console.log("Redux: open chat for ticket with id: " + ticketId);

        messages.sort((a, b) => (a.send_date > b.send_date ? 1 : -1));

        const ticketChats: Chat[] = [];

        for (const chatId in state.chats) {
          if (state.chats[chatId].ticketId) {
            ticketChats.push(state.chats[chatId]);
          }
        }

        const chatIndex = ticketChats.findIndex(
          (chat) => chat.ticketId === ticketId,
        );

        if (chatIndex !== -1) {
          state.chats[ticketChats[chatIndex].id].messages = messages;

          state.selectedChatId = ticketChats[chatIndex].id;
        }
      }

      state.mediaUploadUi = "hide";
    });
    builder.addCase(addMessage.fulfilled, (state, action) => {
      const { clientID, message, isFromCoach } = action.meta.arg;

      if (state.chats[clientID]) {
        if (
          !isFromCoach &&
          !state.chats[clientID].oldestUnreadMessageFromClient
        ) {
          state.chats[clientID].oldestUnreadMessageFromClient =
            new Date().toISOString();
        }

        if (message.to_id === clientID) {
          state.chats[clientID].oldestMessageNeedingResponse = undefined;
          state.chats[clientID].oldestUnreadMessageIdByTrainer = undefined;
          state.chats[clientID].oldestQuestionFromClient = undefined;
        }

        const messageIndex = state.chats[clientID].messages.findIndex(
          (m) => m.message_id === message.message_id,
        );

        if (messageIndex === -1) {
          state.chats[clientID].messages.push(message);
        }
      }
    });
    builder.addCase(fetchAllMessages.pending, (state) => {
      state.allMessagesStatus = "loading";
    });
    builder.addCase(fetchAllMessages.fulfilled, (state, action) => {
      state.allMessagesStatus = "succeeded";

      const unreadMessages = action.payload;

      for (const chatId in unreadMessages) {
        if (!state.chats[chatId] || state.selectedChatId === chatId) {
          continue;
        }

        const messages = unreadMessages[chatId];

        messages.sort((a, b) =>
          (a.send_date as string).localeCompare(b.send_date as string),
        );

        for (const message of messages) {
          const isFromClient = message.from_id === chatId;

          if (isFromClient && !message.read_date) {
            state.chats[chatId].oldestUnreadMessageIdByTrainer =
              message.message_id;

            break;
          }
        }

        state.chats[chatId].messages = messages;
      }
    });
    builder.addCase(fetchAllMessages.rejected, (state) => {
      state.allMessagesStatus = "failed";
    });
    builder.addCase(fetchChats.fulfilled, (state, action) => {
      const newChats = action.payload;

      for (const chat of newChats) {
        state.chats[chat.id] = chat;
      }
    });
    builder.addCase(fetchTicketChats.pending, (state) => {
      state.ticketChatsStatus = "loading";
    });
    builder.addCase(fetchTicketChats.fulfilled, (state, action) => {
      state.ticketChatsStatus = "succeeded";

      const newChats = action.payload;

      for (const chat of newChats) {
        state.chats[chat.id] = chat;
      }
    });
    builder.addCase(fetchTicketChats.rejected, (state) => {
      state.ticketChatsStatus = "failed";
    });
    builder.addCase(fetchOfficialChats.pending, (state) => {
      state.officialChatsStatus = "loading";
    });
    builder.addCase(fetchOfficialChats.fulfilled, (state, action) => {
      state.officialChatsStatus = "succeeded";
      const newChats = action.payload;

      for (const chat of newChats) {
        state.chats[chat.id] = chat;
      }
    });
    builder.addCase(fetchFirstMessages.pending, (state, action) => {
      const chatId = action.meta.arg;

      if (
        state.chats[chatId] &&
        state.chats[chatId].firstMessageFetchState === "idle"
      ) {
        state.chats[chatId].firstMessageFetchState = "fetching";
      }
    });
    builder.addCase(fetchFirstMessages.fulfilled, (state, action) => {
      console.log("Chat: Fetched first messages");

      const chatId = action.meta.arg;

      if (!action.payload) {
        return;
      }

      const { messages } = action.payload;

      if (state.chats[chatId]) {
        const filteredMessages = messages.filter((m) => !m.is_fake);

        for (const message of filteredMessages) {
          const isFromClient = message.from_id === chatId;

          if (isFromClient && !message.read_date) {
            state.chats[chatId].oldestUnreadMessageIdByTrainer =
              message.message_id;

            break;
          }
        }

        state.chats[chatId].messages = messages;
        state.chats[chatId].firstMessageFetchState = "done";

        if (messages.length < 25) {
          state.chats[chatId].loadingState = "endReached";
        }
      }
    });
    builder.addCase(fetchOfficialChats.rejected, (state) => {
      state.officialChatsStatus = "failed";
    });
    builder.addCase(sendMessageAsSms.pending, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          // @ts-expect-error
          state.chats[chatId].messages[messageIndex].send_state = "sending_sms";
        }
      }
    });
    builder.addCase(sendMessageAsSms.fulfilled, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          state.chats[chatId].messages[messageIndex].date_sms_sent =
            new Date().toISOString();
          // @ts-expect-error
          delete state.chats[chatId].messages[messageIndex].send_state;
        }
      }
    });
    builder.addCase(sendMessageAsSms.rejected, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          // @ts-expect-error
          state.chats[chatId].messages[messageIndex].send_state = "error";
        }
      }
    });
    builder.addCase(sendMessageAsEmail.pending, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          // @ts-expect-error
          state.chats[chatId].messages[messageIndex].send_state =
            "sending_email";
        }
      }
    });
    builder.addCase(sendMessageAsEmail.fulfilled, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          state.chats[chatId].messages[messageIndex].date_email_sent =
            new Date().toISOString();
          // @ts-expect-error
          delete state.chats[chatId].messages[messageIndex].send_state;
        }
      }
    });
    builder.addCase(sendMessageAsEmail.rejected, (state, action) => {
      const { messageId, chatId } = action.meta.arg;

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === messageId,
        );

        if (messageIndex !== -1) {
          // @ts-expect-error
          state.chats[chatId].messages[messageIndex].send_state = "error";
        }
      }
    });
    builder.addCase(updateMessage.fulfilled, (state, action) => {
      const newMessage = action.payload.message;

      let chatId = newMessage.from_id;

      if (!state.chats[chatId]) {
        chatId = newMessage.to_id;
      }

      if (state.chats[chatId]) {
        const messageIndex = state.chats[chatId].messages.findIndex(
          (m) => m.message_id === newMessage.message_id,
        );

        if (messageIndex !== -1) {
          state.chats[chatId].messages[messageIndex] = newMessage;
        }
      }
    });
    builder.addCase(markChatAsUnread.fulfilled, (state, action) => {
      const chatIds = action.payload;

      for (const chatId of chatIds) {
        if (state.chats[chatId]) {
          if (!state.chats[chatId].oldestUnreadMessageFromClient) {
            state.chats[chatId].oldestUnreadMessageFromClient =
              new Date().toISOString();
          }
        }
      }

      if (!action.meta.arg) {
        state.isSelectingChats = false;
        state.selectedChatIds = [];
      }
    });
    builder.addCase(toggleChatPinned.fulfilled, (state, action) => {
      const chatIds = action.payload;

      for (const chatId in state.chats) {
        if (chatIds.includes(chatId)) {
          state.chats[chatId].pinned = true;
        } else {
          state.chats[chatId].pinned = false;
        }
      }

      if (!action.meta.arg) {
        state.isSelectingChats = false;
        state.selectedChatIds = [];
      }
    });
    builder.addCase(toggleChatFilter.fulfilled, (state, action) => {
      const newFilter = action.meta.arg;
      const newSelectedChatIds = action.payload;

      const currentIndex = state.selectedFilters.indexOf(newFilter);

      if (currentIndex === -1) {
        state.selectedFilters.push(newFilter);
      } else {
        state.selectedFilters.splice(currentIndex, 1);
      }

      state.selectedChatIds = newSelectedChatIds;
    });
    builder.addCase(stageMassMessages.fulfilled, (state, action) => {
      const massMessages = action.payload;

      for (const massMessage of massMessages) {
        if (state.chats[massMessage.chat.id]) {
          state.pendingMessages[massMessage.chat.id] = massMessage.message;
        }
      }
    });
    builder.addCase(readChat.fulfilled, (state, action) => {
      const { isGhosting, trainerId, disableGhostingProtections } =
        action.payload;
      const chatId = action.meta.arg;

      const chat = state.chats[chatId];

      if ((!isGhosting || disableGhostingProtections) && chat) {
        chat.oldestUnreadMessageFromClient = undefined;

        if (chat?.isGroupChat) {
          if (trainerId) {
            chat.messages.forEach((message) => {
              if (
                !message.read_statuses ||
                (!message.read_statuses[trainerId] &&
                  message.from_id !== trainerId)
              ) {
                message.read_date = new Date().toISOString();

                if (message.read_statuses) {
                  message.read_statuses[trainerId] = new Date().toISOString();
                } else {
                  message.read_statuses = {
                    [trainerId]: new Date().toISOString(),
                  };
                }
              }
            });
          }
        } else {
          chat.messages.forEach((message) => {
            if (!message.read_date && message.from_id === chatId) {
              message.read_date = new Date().toISOString();
            }
          });
        }
      }
    });
    builder.addCase(fetchMoreMessages.pending, (state, action) => {
      const { chatId } = action.meta.arg;

      state.chats[chatId].loadingState = "loading";
    });
    builder.addCase(fetchMoreMessages.fulfilled, (state, action) => {
      const { chatId, all } = action.meta.arg;
      const { messages } = action.payload;

      messages.sort((a, b) => (a.send_date > b.send_date ? 1 : -1));

      state.chats[chatId].messages.unshift(...messages);
      state.chats[chatId].firstChatIndex =
        state.chats[chatId].firstChatIndex - messages.length;
      state.chats[chatId].loadingState = "succeeded";

      if (all || messages.length === 0) {
        state.chats[chatId].loadingState = "endReached";
      }
    });
    builder.addCase(fetchMoreMessages.rejected, (state, action) => {
      const { chatId } = action.meta.arg;

      if (action.payload !== null && action.payload !== "endReached") {
        state.chats[chatId].loadingState = "failed";
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetChat,
  closeChat,
  toggleFullChatMode,
  setFocusedUserId,
  setMediaUploadUi,
  markMessageAsRead,
  markMessageAsTicketed,
  addChat,
  setSentMessage,
  setCurrentTab,
  setCheckInMode,
  updateChat,
  setMessage,
  updateMessageLocal,
  toggleSelectingChats,
  toggleSelectedChat,
  toggleHideClientChat,
  setHideClientChat,
  setChatView,
  syncActionItemsToChat,
  resetPendingMessages,
} = chatSclice.actions;

export default chatSclice.reducer;

export const selectAllChats = (state: RootState) => state.chat.chats;

export const selectChatById = createSelector(
  [selectAllChats, (state, chatId: string) => chatId],
  (chats, chatId) => chats[chatId],
);

export const selectMessageByChatId = (state: RootState, chatId: string) =>
  state.chat.pendingMessages[chatId] ?? "";

export const selectSelectedChat = createSelector(
  [
    selectAllChats,
    (state: RootState) => state.chat.selectedChatId,
    (state: RootState) => state.client.client?.user_id,
  ],
  (chats, selectedChatId, userId) =>
    userId ? chats[userId] : selectedChatId ? chats[selectedChatId] : undefined,
);

export const selectTicketChats = createSelector(
  [
    (state: RootState) => state.chat.chats,
    (state: RootState) => state.tickets.tickets,
  ],
  (chats, tickets) => {
    const ticketChats: Chat[] = [];

    for (const chatId in chats) {
      if (chats[chatId].ticketId && tickets.entities[chats[chatId].ticketId!]) {
        ticketChats.push(chats[chatId]);
      }
    }

    return ticketChats.sort((a, b) => {
      const aTicket = tickets.entities[a.ticketId!];
      const bTicket = tickets.entities[b.ticketId!];

      const dateA = (
        a.messages.length > 0
          ? a.messages[a.messages.length - 1].send_date
          : aTicket!.date_created
      ) as string;
      const dateB = (
        b.messages.length > 0
          ? b.messages[b.messages.length - 1].send_date
          : bTicket!.date_created
      ) as string;

      return dateB.localeCompare(dateA);
    });
  },
);

export const selectClientChats = createSelector(
  [(state: RootState) => state.chat.chats],
  (chats) => {
    const clientChats: Chat[] = [];

    for (const chatId in chats) {
      if (!chats[chatId]?.isGroupChat) {
        clientChats.push(chats[chatId]);
      }
    }

    return clientChats;
  },
);

export const selectOfficialTrainwellChats = createSelector(
  [(state: RootState) => state.chat.chats],
  (chats) => {
    const trainwellChats: Chat[] = [];

    for (const chatId in chats) {
      if (chats[chatId]?.isGroupChat && !chats[chatId].ticketId) {
        trainwellChats.push(chats[chatId]);
      }
    }

    return trainwellChats.sort((a, b) => {
      const dateA = (
        a.messages.length > 0
          ? a.messages[a.messages.length - 1].send_date
          : a.dateCreated
      ) as string;
      const dateB = (
        b.messages.length > 0
          ? b.messages[b.messages.length - 1].send_date
          : b.dateCreated
      ) as string;

      return dateB.localeCompare(dateA);
    });
  },
);

export const selectUnreadTicketCount = (state: RootState) => {
  const ticketChats = selectTicketChats(state);

  const notDoneTicketChats = ticketChats.filter(
    (chat) => selectTicketById(state, chat.ticketId!)?.state !== "done",
  );

  let count = 0;

  notDoneTicketChats.forEach((chat) => {
    if (chat.oldestUnreadMessageFromClient) {
      count++;
    }
  });

  return count;
};

const selectSentActionItems = createSelector(
  [(state: RootState) => state.actionItems.actionItems],
  (actionItems) =>
    actionItems.filter(
      (actionItem) =>
        !actionItem.date_to_send || isPast(new Date(actionItem.date_to_send)),
    ),
);

export const selectChatThemeMode = createSelector(
  [(state: RootState) => state.trainer.trainer?.settings?.theme_chat],
  (trainerChatTheme) => (trainerChatTheme ?? "light") as ThemeMode,
);

export const selectSortedChats = createSelector(
  [
    (state: RootState) => state.chat.chats,
    (_state: RootState, chatSort: ChatSort | null) => chatSort,
    (_state: RootState, _chatSort: ChatSort, search: string) => search,
    selectSentActionItems,
    (state: RootState) => state.chat.selectedChatIds,
    (state: RootState) => state.clients.clients,
    (state: RootState) => state.clients.clientInfo,
    (state: RootState) => selectPrimaryTrainer(state)?.settings.client_sort,
  ],
  (
    chats,
    chatSort,
    search,
    actionItems,
    selectedChatIds,
    clients,
    clientInfo,
    clientSort,
  ) => {
    let newChats: Chat[] = [];

    for (const chatId in chats) {
      if (!chats[chatId]?.isGroupChat) {
        newChats.push(chats[chatId]);
      }
    }

    if (search) {
      newChats = newChats.filter((chat) =>
        chat.clientName.toLowerCase().includes(search.toLowerCase()),
      );
    } else {
      if (chatSort === "newest") {
        newChats.sort((a, b) => {
          return sortChatsByNewestActionItem(a, b, actionItems);
        });
      } else if (chatSort === "match_home") {
        const sort =
          clientSort ??
          ({
            field: "name",
            direction: "asc",
          } as NonNullable<Trainer["settings"]["client_sort"]>);

        const sortedClients = getSortedClientList(clients, sort, clientInfo);

        newChats.sort((a, b) => {
          const aIndex = sortedClients.findIndex(
            (client) => client.user_id === a.id,
          );
          const bIndex = sortedClients.findIndex(
            (client) => client.user_id === b.id,
          );

          return aIndex - bIndex;
        });
      } else {
        newChats.sort((a, b) => {
          return sortChatsByOldestActionItem(a, b, actionItems);
        });
      }
    }

    // Apply additional filtering after the list has already been sorted
    // if (
    //   (chatSort === "action_items" || chatSort === "action_items_oldest") &&
    //   !search
    // ) {
    //   newChats = newChats.filter((chat) => {
    //     const chatActionItems = actionItems.filter(
    //       (item) => item.user_id === chat.id && item.type !== "custom",
    //     );

    //     const hasItems =
    //       chatActionItems.length > 0 ||
    //       chat.oldestMessageNeedingResponse ||
    //       chat.oldestUnreadMessageFromClient

    //     return hasItems || chat.pinned;
    //   });
    // }

    // If the coach has selected chat filters which includes chats exempt from the above filter
    // Append additional selected chats

    const chatIds = newChats.map((chat) => chat.id);

    for (const chatId of selectedChatIds) {
      if (!chatIds.includes(chatId)) {
        newChats.push(JSON.parse(JSON.stringify(chats[chatId])));
      }
    }

    return newChats;
  },
);

export const selectMassMessages = createSelector(
  [
    (state: RootState) => state.chat.selectedChatIds,
    (state: RootState) => state.chat.chats,
    (state: RootState) => state.clients.clients,
    (state: RootState) => state.clients.clientInfo,
    (_state: RootState, messageHtml: string) => messageHtml,
    (_state: RootState, messageHtml: string, userIds?: string[]) => userIds,
  ],
  (selectedChatIds, chats, clients, allClientInfo, messageHtml, userIds) => {
    const selectedChats: Chat[] = [];

    if (userIds) {
      for (const userId of userIds) {
        selectedChats.push(chats[userId]);
      }
    } else {
      for (const chatId of selectedChatIds) {
        selectedChats.push(chats[chatId]);
      }
    }

    const messages: { message: string; chat: Chat; error: boolean }[] = [];

    const editor = new Editor({
      content: messageHtml,
      extensions: [
        Document,
        Paragraph,
        Text,
        Mention.configure({
          deleteTriggerWithBackspace: true,
          suggestion: magicKeySuggestionOptions,
          HTMLAttributes: {
            class: "mention",
          },
        }),
      ],
    });

    for (const chat of selectedChats) {
      let foundError = false;

      const client = clients.find((c) => c.user_id === chat.id);
      const clientInfo = allClientInfo[chat.id];

      if (!client || !clientInfo) {
        messages.push({
          message: "{error: no client info. Refresh the dash}",
          chat: chat,
          error: true,
        });

        continue;
      }

      const serializedMessage = editor.getText({
        blockSeparator: "\n",
        textSerializers: {
          mention: ({ node, parent, index }) => {
            const lastWord =
              index <= 0
                ? undefined
                : parent
                    .child(index - 1)
                    .text?.split(" ")
                    .at(-2);

            const { message, foundError: foundErrorInKey } =
              getSerializedMessage({
                client: client,
                clientInfo: allClientInfo,
                magicKeyId: node.attrs.id,
                lastWord: lastWord,
              });

            if (foundErrorInKey) {
              foundError = true;
            }

            return message;
          },
        },
      });

      messages.push({
        message: serializedMessage,
        chat: chat,
        error: foundError,
      });
    }

    return messages;
  },
);

export function getSortedClientList(
  clients: Client[],
  sort: {
    field:
      | "name"
      | "next_workout"
      | "missed_streak"
      | "workout_completion"
      | "last_message"
      | "last_workout"
      | "tenure_with_coach"
      | "tenure_with_trainwell"
      | "weeks";
    direction: "asc" | "desc";
  },
  clientInfo: ClientInfo,
) {
  const clientList = [...clients];

  if (sort.field === "name") {
    return clientList.sort((a, b) => {
      return sortClientsByName(a, b, sort.direction);
    });
  } else if (sort.field === "tenure_with_coach") {
    return clientList.sort((a, b) => {
      return sortClientsByTenureCoach(a, b, sort.direction);
    });
  } else if (sort.field === "tenure_with_trainwell") {
    return clientList.sort((a, b) => {
      return sortClientsByTenureTrainwell(a, b, sort.direction);
    });
  } else if (sort.field === "last_workout") {
    return clientList.sort((a, b) => {
      return sortClientsByLastWorkoutDate(a, b, sort.direction);
    });
  } else if (sort.field === "last_message") {
    return clientList.sort((a, b) => {
      return sortClientsByLastMessageDate(a, b, sort.direction);
    });
  } else if (sort.field === "missed_streak") {
    return clientList.sort((a, b) => {
      return sortClientsByMissedDays(a, b, sort.direction);
    });
  } else if (sort.field === "workout_completion") {
    return clientList.sort((a, b) => {
      return sortClientsByWorkoutCompletion(a, b, sort.direction);
    });
  } else if (sort.field === "weeks") {
    return clientList.sort((a, b) => {
      return sortClientsByWeeks(a, b, sort.direction, clientInfo);
    });
  } else if (sort.field === "next_workout") {
    return clientList.sort((a, b) => {
      return sortClientsByNextWorkout(a, b, sort.direction, clientInfo);
    });
  }

  return [];
}

export const selectClientChatsSmol = createSelector(
  [(state: RootState) => state.chat.chats],
  (chats) => {
    const clientChats: Chat[] = [];

    for (const chatId in chats) {
      if (!chats[chatId]?.isGroupChat) {
        clientChats.push(chats[chatId]);
      }
    }

    return clientChats.map((chat) => ({
      id: chat.id,
      clientName: chat.clientName,
    }));
  },
);

const getChats = (state: RootState) => selectClientChatsSmol(state);
const getUserIds = (state: RootState, userIds: string[]) => userIds;
const getSearch = (state: RootState, userIds: string[], search: string) =>
  search;

export const selectFilteredCheckInChats = createSelector(
  [getChats, getUserIds, getSearch],
  (newChats, userIds, search) => {
    if (search === "") {
      return newChats
        .filter((c) => userIds.includes(c.id))
        .sort((a, b) => {
          const aIndex = userIds.indexOf(a.id);
          const bIndex = userIds.indexOf(b.id);
          return aIndex - bIndex;
        })
        .map((c) => c.id);
    } else {
      const lowerCaseSearch = search.toLowerCase();
      return newChats
        .filter((c) => c.clientName.toLowerCase().includes(lowerCaseSearch))
        .map((c) => c.id);
    }
  },
);
