import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import VerticalAlignTopRoundedIcon from "@mui/icons-material/VerticalAlignTopRounded";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  LinearProgress,
  Portal,
  Typography,
} from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { getOldestUnreadMessageByClient } from "@trainwell/features";
import type { ActionItemWorkoutReview } from "@trainwell/types";
import { format, isFuture } from "date-fns";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import { useInView } from "react-intersection-observer";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getDateWithTimezoneOffset } from "src/lib/date";
import { selectActionItemsForClient } from "src/slices/actionItemSlice";
import {
  fetchFirstMessages,
  fetchMoreMessages,
  readChat,
  selectSelectedChat,
  updateChat,
} from "src/slices/chatSlice";
import { selectTicketById } from "src/slices/ticketsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { ChatMediaUpload } from "./ChatMediaUpload";
import ChatMessageBar from "./ChatMessageBar";
import ChatMessagesLoading from "./ChatMessagesLoading";
import { MessageCell } from "./messages/MessageCell";
import TicketMessageCard from "./messages/TicketMessageCard";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export default function ClientChat() {
  const { ref, inView } = useInView();
  const dispatch = useAppDispatch();
  const selectedChat = useAppSelector(selectSelectedChat);
  const clientBanner = useAppSelector((state) =>
    state.trainer.trainer?.banner_custom &&
    isFuture(state.trainer.trainer.banner_custom.date_active_until)
      ? state.trainer.trainer.banner_custom.text
      : state.client.client?.banner_coach?.active
        ? state.client.client?.banner_coach?.text
        : "",
  );
  const customBannerActiveUntilDate = useAppSelector((state) =>
    state.trainer.trainer?.banner_custom &&
    isFuture(state.trainer.trainer.banner_custom.date_active_until)
      ? state.trainer.trainer.banner_custom.date_active_until
      : null,
  );

  const trainer = useAppSelector(selectPrimaryTrainer);
  const shouldScroll = useRef(false);
  const dashMode = useAppSelector((state) => state.app.dashMode);
  const ticketForChat = useAppSelector((state) =>
    selectedChat?.ticketId
      ? selectTicketById(state, selectedChat.ticketId)
      : undefined,
  );
  const mediaUploadUi = useAppSelector((state) => state.chat.mediaUploadUi);
  const isAuditMode = useAppSelector((state) => state.app.isAuditMode);

  const firstLoad = useRef(false);

  const messages = useMemo(
    () => selectedChat?.messages ?? [],
    [selectedChat?.messages],
  );
  const messageCount = messages.length;

  useEffect(() => {
    if (inView) {
      if (!firstLoad.current) {
        firstLoad.current = true;

        let index = messageCount - 1;

        if (
          selectedChat?.oldestUnreadMessageIdByTrainer &&
          selectedChat.messages?.length
        ) {
          index = selectedChat.messages.findIndex(
            (m) => m.message_id === selectedChat.oldestUnreadMessageIdByTrainer,
          );
        }

        virtualizerRef.current?.scrollToIndex(index, {
          align: "start",
        });
      } else {
        loadMoreMessages();
      }
    }
  }, [inView]);

  const virtualizerRef = useRef<ReturnType<typeof useVirtualizer> | null>(null);

  const itemSize = 10;

  if (
    virtualizerRef.current &&
    messageCount !== virtualizerRef.current.options.count
  ) {
    if (shouldScroll.current) {
      shouldScroll.current = false;

      const delta = messageCount - virtualizerRef.current.options.count;
      const nextOffset =
        (virtualizerRef.current.scrollOffset ?? 0) + delta * itemSize;

      virtualizerRef.current.scrollOffset = nextOffset;
      virtualizerRef.current.scrollToOffset(nextOffset, { align: "start" });
      virtualizerRef.current.calculateRange();
      console.log("Chat: Adjusted scroll offset", nextOffset);
    } else {
      virtualizerRef.current?.scrollToIndex(messageCount - 1, {
        align: "start",
      });
    }
  }
  const parentRef = useRef(null);
  const virtualizer = useVirtualizer({
    getScrollElement: () => parentRef.current,
    count: messageCount,
    estimateSize: () => itemSize,
    getItemKey: useCallback((index) => messages[index].message_id, [messages]),
    overscan: 25,
    scrollMargin: 50,
  });

  useIsomorphicLayoutEffect(() => {
    virtualizerRef.current = virtualizer;
  });

  const items = virtualizer.getVirtualItems();

  const [paddingTop, paddingBottom] =
    items.length > 0
      ? [
          Math.max(0, items[0].start - virtualizer.options.scrollMargin),
          Math.max(0, virtualizer.getTotalSize() - items[items.length - 1].end),
        ]
      : [0, 0];

  useEffect(() => {
    if (!selectedChat?.id) {
      return;
    }

    // dispatch(fetchFirstMessages(selectedChat.id));

    dispatch(
      updateChat({
        id: selectedChat?.id ?? "",
        firstMessageFetchState: "idle",
      }),
    );
  }, [dispatch, isAuditMode]);

  useEffect(() => {
    if (selectedChat?.firstMessageFetchState === "idle") {
      dispatch(fetchFirstMessages(selectedChat.id));
    }
  }, [selectedChat, dispatch]);

  useEffect(() => {
    if (
      selectedChat &&
      selectedChat.messages.length > 0 &&
      dashMode !== "programming"
    ) {
      dispatch(readChat(selectedChat.id));
    }
  }, [dispatch, selectedChat, trainer?.trainer_id, dashMode]);

  function scrollToTop() {
    if (virtualizerRef.current) {
      virtualizerRef.current.scrollToOffset(0, { align: "start" });
    }
  }

  function handleGoToTop() {
    if (selectedChat?.loadingState === "endReached") {
      scrollToTop();
    } else {
      shouldScroll.current = false;

      dispatch(
        fetchMoreMessages({
          chatId: selectedChat!.id,
          all: true,
        }),
      ).then(() => {
        scrollToTop();
      });
    }
  }

  function loadMoreMessages() {
    if (selectedChat?.loadingState === "loading") {
      return;
    }

    shouldScroll.current = true;

    dispatch(fetchMoreMessages({ chatId: selectedChat?.id ?? "" }));
  }

  const oldestUnreadMessageIdByClient = useMemo(() => {
    return getOldestUnreadMessageByClient({
      messages: messages ?? [],
      trainerId: trainer?.trainer_id ?? "",
    })?.message_id;
  }, [
    selectedChat?.id,
    selectedChat?.firstMessageFetchState,
    trainer?.trainer_id,
    messages,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
      }}
    >
      {clientBanner && (
        <Box
          sx={{
            px: 1,
            py: 0.5,
            backgroundColor: (theme) => theme.palette.warningSurface.main,
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              width: 19,
              height: 19,
              mr: 1,
            }}
          >
            <CalendarMonthRoundedIcon sx={{ fontSize: 13 }} />
          </Avatar>
          <Box>
            <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
              {clientBanner}
            </Typography>
            <Typography variant="overline">
              {customBannerActiveUntilDate !== null
                ? `Set by you until ${format(customBannerActiveUntilDate, "h:mm a")}`
                : "Set by trainwell"}
            </Typography>
          </Box>
        </Box>
      )}
      {selectedChat?.firstMessageFetchState === "done" ? (
        <div
          style={{
            overflowY: "auto",
            flex: 1,
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
          ref={parentRef}
        >
          <Box
            sx={{
              height: "50px",
              maxHeight: "50px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              flexDirection: "column",
            }}
            ref={ref}
          >
            {selectedChat.loadingState === "loading" ||
            selectedChat.loadingState === "succeeded" ? (
              <>
                <Typography sx={{ textAlign: "center" }}>
                  Loading more...
                </Typography>
                <LinearProgress sx={{ width: 200 }} />
              </>
            ) : selectedChat.loadingState === "endReached" ? (
              <Typography sx={{ textAlign: "center" }}>
                The very beginning
              </Typography>
            ) : selectedChat.loadingState === "failed" ? (
              <Button
                startIcon={<ErrorRoundedIcon />}
                onClick={() => {
                  loadMoreMessages();
                }}
              >
                Error loading messages. Try again
              </Button>
            ) : (
              <Button
                onClick={() => {
                  loadMoreMessages();
                }}
              >
                Load more
              </Button>
            )}
          </Box>
          {ticketForChat && <TicketMessageCard chat={selectedChat} />}
          <div
            style={{
              overflowAnchor: "none",
              paddingTop,
              paddingBottom,
            }}
          >
            {items.map((virtualRow) => {
              const message = messages[virtualRow.index];
              const nextMessage = messages.at(virtualRow.index + 1);
              const previousMessage =
                virtualRow.index === 0
                  ? undefined
                  : messages.at(virtualRow.index - 1);

              return (
                <MessageCell
                  key={virtualRow.key}
                  index={virtualRow.index}
                  ref={virtualizer.measureElement}
                  message={message}
                  trainerId={trainer?.trainer_id ?? ""}
                  nextMessage={nextMessage}
                  previousMessage={previousMessage}
                  isOldestUnreadMessageByClient={
                    message.message_id === oldestUnreadMessageIdByClient
                  }
                  isOldestUnreadMessageByTrainer={
                    message.message_id ===
                    selectedChat.oldestUnreadMessageIdByTrainer
                  }
                />
              );
            })}
          </div>
          <Footer onGoToTop={handleGoToTop} />
        </div>
      ) : selectedChat ? (
        <ChatMessagesLoading />
      ) : null}
      {selectedChat && <ChatMessageBar />}
      {(mediaUploadUi === "show" || mediaUploadUi === "uploading") && (
        <Portal>
          <ChatMediaUpload />
        </Portal>
      )}
    </Box>
  );
}

type FooterProps = {
  onGoToTop: () => void;
};

function Footer({ onGoToTop }: FooterProps) {
  const selectedChat = useAppSelector(selectSelectedChat);
  const workoutActionItemCount = useAppSelector(
    (state) =>
      selectActionItemsForClient(state, selectedChat?.id ?? "").filter(
        (ai) =>
          ai.type === "workout_review" &&
          (ai as ActionItemWorkoutReview).is_internal,
      ).length,
  );

  return (
    <Box>
      {workoutActionItemCount > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              borderRadius: "50px",
              backgroundColor: (theme) => theme.palette.primary.main,
              px: 1,
              py: 0.5,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.primary.contrastText,
                textAlign: "center",
              }}
            >
              {workoutActionItemCount} unread workout
              {workoutActionItemCount > 1 ? "s" : ""}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          pb: 1,
        }}
      >
        <LoadingButton
          variant="text"
          size="small"
          startIcon={<VerticalAlignTopRoundedIcon />}
          onClick={onGoToTop}
          loading={selectedChat?.loadingState === "loading"}
        >
          Top
        </LoadingButton>
        <Box>
          {selectedChat?.clientTimezoneOffset !== undefined && (
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                textAlign: "right",
              }}
            >
              Client local time:{" "}
              {format(
                getDateWithTimezoneOffset(
                  new Date(),
                  selectedChat?.clientTimezoneOffset ?? 0,
                ),
                "hh:mm aaa",
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
