import VolunteerActivismRoundedIcon from "@mui/icons-material/VolunteerActivismRounded";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import type {
  ActionItemCustom,
  Message as MessageType,
} from "@trainwell/types";
import {
  differenceInCalendarDays,
  format,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
} from "date-fns";
import { forwardRef, memo } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName } from "src/lib/coachUtility";
import { selectSentActionItems } from "src/slices/actionItemSlice";
import { selectSelectedChat } from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";
import { selectTrainerNames } from "src/slices/trainersSlice";
import { SaveForLaterIcon } from "../SaveForLaterIcon";
import { Message } from "./Message";

type Props = {
  index: number;
  message: MessageType;
  trainerId: string;
  previousMessage?: MessageType;
  nextMessage?: MessageType;
  isOldestUnreadMessageByClient?: boolean;
  isOldestUnreadMessageByTrainer?: boolean;
};

export const MessageCell = memo(
  forwardRef(function MessageCell(
    {
      index,
      message,
      trainerId,
      nextMessage,
      previousMessage,
      isOldestUnreadMessageByClient,
      isOldestUnreadMessageByTrainer,
    }: Props,
    ref: React.Ref<HTMLDivElement>,
  ) {
    const selectedChat = useAppSelector((state) => {
      const chat = selectSelectedChat(state);

      if (!chat) {
        return undefined;
      }

      return {
        isGroupChat: chat.isGroupChat,
        isTrainwell: chat.isTrainwell,
        oldTrainerId: chat.oldTrainerId,
        id: chat.id,
        clientName: chat.clientName,
      };
    }, shallowEqual);
    const forceSmsDisabled = useAppSelector(
      (state) =>
        selectClientById(state, selectedChat?.id ?? "")?.settings.disable_sms ??
        false,
    );
    const isAuditMode = useAppSelector((state) => state.app.isAuditMode);
    const trainerNames = useAppSelector(selectTrainerNames);
    const saveForLaterType = useAppSelector(
      (state) =>
        (
          selectSentActionItems(state).find(
            (ai) =>
              ai.type === "custom" &&
              ai.content?.message_id === message.message_id,
          ) as undefined | ActionItemCustom
        )?.content?.custom_type,
    );

    if (!selectedChat) {
      return;
    }

    const isSavedForLater = Boolean(saveForLaterType);

    const headshotUrl =
      message.from_headshot_url ||
      trainerNames.find((trainer) => trainer.trainer_id === message.from_id)
        ?.headshot_url;

    let showName = selectedChat.isGroupChat && message.from_id !== trainerId;

    const combineBottom = Boolean(
      nextMessage &&
        nextMessage.from_id === message.from_id &&
        nextMessage.type !== "notification",
    );

    if (
      showName &&
      previousMessage &&
      previousMessage.from_id === message.from_id
    ) {
      showName = false;
    }

    const fromMe = selectedChat.isTrainwell
      ? message.from_id === "copilot"
      : message.from_id === trainerId ||
        message.from_id === selectedChat.oldTrainerId ||
        (message.trainer_id_interim === trainerId &&
          message.from_id !== selectedChat.id);

    const actuallyFromThisCoach =
      !message.trainer_id_interim && message.from_id === trainerId;

    let dayMarkerText: string | null = null;

    if (
      nextMessage &&
      (isAuditMode ||
        differenceInCalendarDays(nextMessage.send_date, message.send_date) >= 1)
    ) {
      if (isToday(nextMessage.send_date)) {
        dayMarkerText = format(nextMessage.send_date, "'Today' h:mm aa");
      } else if (isYesterday(nextMessage.send_date)) {
        dayMarkerText = format(nextMessage.send_date, "'Yesterday' h:mm aa");
      } else if (isThisWeek(nextMessage.send_date)) {
        dayMarkerText = format(nextMessage.send_date, "EEEE h:mm aa");
      } else if (isThisYear(nextMessage.send_date)) {
        dayMarkerText = format(nextMessage.send_date, "MMM d h:mm aa");
      } else {
        dayMarkerText = format(nextMessage.send_date, "MMM d, yyyy h:mm aa");
      }
    }

    return (
      <Box ref={ref} data-index={index}>
        {!selectedChat.isGroupChat && isOldestUnreadMessageByClient && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              py: 2,
            }}
          >
            <Box
              sx={{
                flex: 1,
                height: "1px",
                backgroundColor: (theme) => theme.palette.divider,
                mr: 1,
              }}
            />
            <Typography
              variant="overline"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {selectedChat.clientName} read to here
            </Typography>
          </Box>
        )}
        {!selectedChat.isGroupChat && isOldestUnreadMessageByTrainer && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              px: 2,
              py: 2,
            }}
          >
            <Typography
              variant="overline"
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              New
            </Typography>
            <Box
              sx={{
                flex: 1,
                height: "1px",
                backgroundColor: (theme) => theme.palette.primary.main,
                ml: 1,
              }}
            />
          </Box>
        )}
        {/* Message content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: fromMe ? "flex-end" : "flex-start",
            pb: combineBottom
              ? message.reactions && message.reactions.length > 0
                ? 1.5
                : 0.25
              : 2,
          }}
        >
          {isSavedForLater && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                pb: 0.25,
              }}
            >
              <SaveForLaterIcon
                type={saveForLaterType ?? "other"}
                fontSize="inherit"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light" ? "#3b82f6" : "#93c5fd",
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light" ? "#3b82f6" : "#93c5fd",
                  pl: 1,
                  fontWeight: "bold",
                }}
              >
                Saved for later
              </Typography>
            </Box>
          )}
          {message.nice && (
            <Box sx={{ display: "flex", alignItems: "center", pb: 0.25 }}>
              <VolunteerActivismRoundedIcon
                fontSize="inherit"
                sx={{
                  color: (theme) => theme.palette.pink.main,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: (theme) => theme.palette.pink.main,
                  pl: 1,
                  fontWeight: "bold",
                }}
              >
                Nice message
              </Typography>
            </Box>
          )}
          {showName && (
            <Box sx={{ display: "flex", alignItems: "center", pb: 0.5 }}>
              {headshotUrl && (
                <img
                  style={{
                    borderRadius: "8px",
                    paddingRight: "8px",
                  }}
                  src={headshotUrl}
                  width={16}
                  height={16}
                  alt="headshot"
                />
              )}
              <Typography variant="overline">
                {message.from_first_name && message.from_last_name
                  ? `${message.from_first_name} ${message.from_last_name}`
                  : getTrainerName(message.from_id, trainerNames)}
              </Typography>
            </Box>
          )}
          <Message
            message={message}
            isFromMe={fromMe}
            isFromOtherCoach={Boolean(
              fromMe &&
                !actuallyFromThisCoach &&
                (message.trainer_id_interim === selectedChat.oldTrainerId ||
                  message.trainer_id_interim !== trainerId),
            )}
            combineBottom={combineBottom}
            forceSmsDisabled={forceSmsDisabled}
            showName={showName}
            isSavedForLater={isSavedForLater}
          />
          <Stack
            direction={"column"}
            spacing={0.5}
            useFlexGap
            alignItems={fromMe ? "flex-end" : "flex-start"}
          >
            {/* @ts-expect-error */}
            {(message.send_state === "sending_sms" ||
              // @ts-expect-error
              message.send_state === "sending_email") && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CircularProgress size={12} sx={{ pr: 1 }} />
                <Typography variant="overline">
                  Sending as {/* @ts-expect-error */}
                  {message.send_state === "sending_sms" ? "SMS" : "email"}
                </Typography>
              </Box>
            )}
            {/* @ts-expect-error */}
            {message.send_state === "error" && (
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.error.main }}
              >
                Error sending
              </Typography>
            )}
            {message.date_sms_sent && (
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Sent as SMS{" "}
                {format(new Date(message.date_sms_sent), "MMM d, h:mm aaa")}
              </Typography>
            )}
            {message.date_email_sent && (
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Sent as email{" "}
                {format(new Date(message.date_email_sent), "MMM d, h:mm aaa")}
              </Typography>
            )}
            {message.source === "sms" && (
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                Sent by SMS
              </Typography>
            )}
          </Stack>
        </Box>
        {dayMarkerText && (
          <Typography variant="overline" sx={{ textAlign: "center", py: 1 }}>
            {dayMarkerText}
          </Typography>
        )}
      </Box>
    );
  }),
);
